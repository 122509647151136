<template>
  <div class="container">
    <h1>JumpWorld Befehle</h1>
    <div class="row mb-3">
      <div class="col-lg-12 text-center table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Befehl</th>
            <th scope="col">Erklärung</th>
          </tr>
          </thead>
          <tr>
            <td>/b</td>
            <td>Öffnet das Bau-Info-Menü</td>
          </tr>
          <tr>
            <td>/checkpoint, /fall, /kill</td>
            <td>Teleportiert dich zurück zum letzten Checkpoint</td>
          </tr>
          <tr>
            <td>/duel accept &lt;Spieler&gt;</td>
            <td>Nimmt die Einladung in ein Duell an</td>
          </tr>
          <tr>
            <td>/duel invite &lt;Name&gt;</td>
            <td>Lädt einen Spieler in ein Duell ein</td>
          </tr>
          <tr>
            <td>/duel kick &lt;Name&gt;</td>
            <td>Kickt einen Spieler aus einem Duell</td>
          </tr>
          <tr>
            <td>/duel leave &lt;Name&gt;</td>
            <td>Verlässt ein Duell</td>
          </tr>
          <tr>
            <td>/enter &lt;ID&gt;, /e &lt;ID&gt;</td>
            <td>Betritt ein bestimmtes Jump 'n' Run</td>
          </tr>
          <tr>
            <td>/join &lt;Name&gt;</td>
            <td>Betritt das Jump 'n' Run, auf dem sich [Spieler] gerade befindet</td>
          </tr>
          <tr>
            <td>/jump</td>
            <td>Wechselt zwischen Jump- und Bau-Modus</td>
          </tr>
          <tr>
            <td>/kit</td>
            <td>Gibt dir die Start-Items im Bau-Modus</td>
          </tr>
          <tr>
            <td>/leave, /l</td>
            <td>Verlässt das aktuelle Jump 'n' Run</td>
          </tr>
          <tr>
            <td>/setblock, /sb</td>
            <td>Platziert den Block in deiner Hand unter dir</td>
          </tr>
          <tr>
            <td>/setstart</td>
            <td>Setzt den Startpunkt deines Jump 'n' Runs</td>
          </tr>
          <tr>
            <td>/top &lt;ID&gt;</td>
            <td>Zeigt die Top-Zeiten des Jump 'n' Runs mit der ID &lt;ID&gt; an</td>
          </tr>
          <tr>
            <td>/top &lt;ID&gt; -f</td>
            <td>Zeigt die Topzeiten von Freunden und Duellmitgliedern des Jump 'n' Runs mit der ID &lt;ID&gt; an</td>
          </tr>
          <tr>
            <td><a href="https://howto.timolia.de/ranks/premium/" target="_blank"><span class="pro">Pro</span> oder höher:</a></td>
            <td></td>
          </tr>
          <tr>
            <td>/multibuild &lt;3, 6&gt;, /mb &lt;3, 6&gt;</td>
            <td>Du kannst bis zu 3 (ab Pro) oder bis zu 6 (ab MVP+) Blöcke auf einmal setzen</td>
          </tr>
          <tr>
            <td><a href="https://howto.timolia.de/ranks/premium/" target="_blank"><span class="mvp">MVP</span> oder höher:</a></td>
            <td></td>
          </tr>
          <tr>
            <td>/skull [name]</td>
            <td>Gibt dir einen Spielerkopf</td>
          </tr>
          <tbody>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutorialJumpWorld"
}
</script>

<style scoped>
.pro {
  color: #FFAA00;
}

.mvp {
  color: #55FF55;
}
</style>
